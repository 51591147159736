<template>
  <div class="invite-root-container">
    <invite ref="invite" @show-invite-receive-modal="showInviteReceiveModal" />
    <invite-member ref="inviteMember" @show-contact-detail-modal="showContactDetailModal" style="margin-top:30px;" />
    <invite-receive-modal
      ref="inviteReceiveModal"
      @show-contact-detail-modal="showContactDetailModal"
    ></invite-receive-modal>
    <contact-detail-modal ref="contactDetailModal"></contact-detail-modal>
  </div>
</template>

<script>
import Invite from "./Panel/Invite";
import InviteMember from "./Panel/InviteMember";
import InviteReceiveModal from "./Modal/InviteReceiveModal";
import ContactDetailModal from "../Contact/Modal/ContactDetailModal";
export default {
  components: {
    invite: Invite,
    "invite-member": InviteMember,
    "invite-receive-modal": InviteReceiveModal,
    "contact-detail-modal": ContactDetailModal,
  },
  // data() {
  //   return {
  //     projectId: 0,
  //   }
  // },
  // mounted() {
  //   this.projectId = this.$router.currentRoute.params.id;
  //   this.refresh();
  // },
  // beforeRouteUpdate(to, from, next) {
  //   if (this.projectId != to.params.id) {
  //     this.projectId = to.params.id;
  //     this.refresh();
  //   }
  //   next();
  // },
  methods: {
    showInviteReceiveModal(item) {
      this.$refs.inviteReceiveModal.open(item);
    },
    showContactDetailModal(id) {
      this.$refs.contactDetailModal.open(id);
    },
    refresh(){
      this.$refs.invite.refresh();
      this.$refs.inviteMember.refresh();
    }
  },
};
</script>

<style scoped>
.invite-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 50px;
  overflow-y: scroll;
}
</style>