<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div class="invite-receive-modal-root-container" v-if="isShow">
      <img
        :src="icons.person"
        style="align-self:flex-end;cursor:pointer;"
        @click="showMemberDetail"
      />
      <my-avatar
        :name="item.client_user_name"
        :img_url="item.img_url"
        :size="83"
        style="margin-top:40px;margin-bottom:10px;"
      ></my-avatar>
      <div style="display:flex;flex-rection:row;align-items:center;margin-bottom:40px;">
        <img :src="icons.pass" style="width:15px;height:17px;margin-right:10px;" />
        <span style="font-size: 15px;font-weight: bold;color: #3C3C3C;">{{ item.client_user_name }}</span>
      </div>
      <div style="font-size: 15px;font-weight: bold;color: #3C3C3C;">申请加入#{{ projectName }}#</div>
      <div style="font-size: 12px;color: #3A3A3A;margin-top:20px;margin-bottom:">通过游用工申请</div>

      <div style="margin-top:30px;" class="invite-reveive-modal-info-item">
        <Icon :size="34" type="md-phone-portrait" />
        <div
          style="font-size: 15px;font-weight: bold;color: #5F5F5F;margin-left:10px;min-width:70px;"
        >手机号</div>
        <div style="font-size: 15px;color: #5F5F5F;">{{ item.client_user_phone }}</div>
      </div>
      <div style="margin-top:20px;" class="invite-reveive-modal-info-item">
        <Icon :size="34" type="md-mail" />
        <div
          style="font-size: 15px;font-weight: bold;color: #5F5F5F;margin-left:10px;min-width:70px;"
        >邮箱</div>
        <div style="font-size: 15px;color: #5F5F5F;">-</div>
      </div>
      <div style="margin-top:30px;" class="invite-reveive-modal-info-item">
        <img :src="icons.idcard" style="width:34px;height:24px;" />
        <div
          style="font-size: 15px;font-weight: bold;color: #5F5F5F;margin-left:10px;min-width:70px;"
        >身份证</div>
        <div style="font-size: 15px;color: #5F5F5F;">{{ item.client_user_idnum }}</div>
      </div>
      <div class="invite-receive-modal-options-container">
        <div class="invite-recived-modal-item-icon-container" @click="refuse">
          <img :src="icons.refuse" style="transform:scale(1.5);margin-bottom:15px;" />
          <span style="font-size:12px">拒绝</span>
        </div>
        <div class="invite-recived-modal-item-icon-container" @click="accept">
          <img :src="icons.accept" style="transform:scale(1.5);margin-bottom:15px;" />
          <span style="font-size:12px">接收</span>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      item: {},
      projectId: 0,
      projectName:"",
      icons: icon,
      isShow: false,
      isShowModal: false,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.projectGetBasicAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
    })
      .then((res) => {
        this.projectName = res.name;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      projectGetBasicAction: "projectGetBasic",
      projectSetJoinStateAction: "projectSetJoinState",
    }),
    open(item) {
      this.item = item;
      this.isShowModal = true;
      setTimeout(() => {
        this.isShow = true;
      }, 200);
    },
    accept() {
      this.projectSetJoinStateAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        client_user_id: this.item.client_user_id,
        state: 2,
      })
        .then((res) => {
          this.$parent.refresh();
          this.isShowModal = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    refuse() {
      this.projectSetJoinStateAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        client_user_id: this.item.client_user_id,
        state: 3,
      })
        .then((res) => {
          this.$parent.refresh();
          this.isShowModal = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showMemberDetail() {
      this.$emit("show-contact-detail-modal", this.item.client_user_id);
    },
  },
};
</script>

<style scoped>
.invite-receive-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 30px 15px;
}
.invite-reveive-modal-member-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.invite-reveive-modal-info-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 60%;
}
.invite-receive-modal-options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  width: 50%;
}
.invite-recived-modal-item-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0px 20px;
}
</style>