<template>
  <div class="invite-top-container">
    <div class="invite-header-container">
      <span class="invite-title">进行中的要约</span>
      <slide-tabber
        style="align-self:center;margin-bottom:20px;"
        :tabs="tabs"
        @on-change="tabChange"
      />
    </div>
    <div v-if="currentTab == 0 && memberList.length > 0" class="invite-main-container">
      <invite-recived-item
        style="margin:10px;"
        v-for="item in memberList"
        :key="item.client_user_id"
        :data-source="item"
        @click.native="showInviteReceiveModal(item)"
      />
    </div>
    <span v-else-if="currentTab == 0">暂无收到的加入请求</span>
    <span v-else>暂无发出的加入请求</span>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import InviteRecivedItem from "../Item/InvitieRecivedItem";
import SlideTabber from "../../../components/SlideTabbar";
export default {
  components: {
    "slide-tabber": SlideTabber,
    "invite-recived-item": InviteRecivedItem,
  },
  data() {
    return {
      projectId: 0,
      tabs: [
        {
          name: "收到的加入请求",
          count: 0,
        },
        {
          name: "发出的加入要约",
          count: 0,
        },
      ],
      currentTab: 0,
      memberList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  methods: {
    ...mapActions({
      projectGetMemberJoinReqAction: "projectGetMemberJoinReq",
    }),
    tabChange(index) {
      this.currentTab = index;
    },
    showInviteReceiveModal(item) {
      this.$emit("show-invite-receive-modal", item);
    },
    refresh() {
      this.projectGetMemberJoinReqAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          this.memberList = res;
          this.tabs[0].count = this.memberList.length;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.invite-top-container {
  display: flex;
  flex-direction: column;
}
.invite-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}
.invite-title {
  font-size: 23px;
  font-weight: bold;
  margin-top: -15px;
  margin-right: 30px;
}
.invite-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
</style>