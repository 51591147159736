<template>
  <div class="invite-recived-item-root-container">
    <my-avatar :size="50" :name="dataSource.client_user_name" :img_url="dataSource.img_url" />
    <div class="invite-recived-item-detail-container">
      <span
        style="font-size: 15px;font-weight:bold;margin-bottom:8px;"
      >{{dataSource.client_user_name}}</span>
      <span style="font-size:12px">通过游用工申请</span>
    </div>
    <div class="invite-recived-item-icon-container" @click.stop="accept">
      <img :src="icons.accept" style="width:18px;height:18px" />
      <span style="font-size:12px;margin-top:2px;">接受</span>
    </div>
    <div class="invite-recived-item-icon-container" @click.stop="refuse">
      <img :src="icons.refuse" style="width:18px;height:18px" />
      <span style="font-size:12px;margin-top:2px;">拒绝</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      projectSetJoinStateAction: "projectSetJoinState",
    }),
    accept() {
      this.projectSetJoinStateAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        client_user_id: this.dataSource.client_user_id,
        state: 2,
      })
        .then((res) => {
          this.$parent.$parent.refresh();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    refuse() {
      this.projectSetJoinStateAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        client_user_id: this.dataSource.client_user_id,
        state: 3,
      })
        .then((res) => {
          this.$parent.$parent.refresh();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.invite-recived-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 1px #dddddd;
}
.invite-recived-item-detail-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 50px;
}
.invite-recived-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.invite-recived-item-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0px 20px;
}
</style>