<template>
  <div class="invite-member-container">
    <div class="invite-member-header-container">
      <span class="invite-title">项目成员</span>
      <span style="font-size: 12px; color: #838383; margin-right: 20px">{{ memberList.length }}人</span>
    </div>
    <div class="invite-member-main-container">
      <invite-member-item style="margin: 10px" v-for="item in memberList" :key="item.client_user_id" :data-source="item"
        @click.native="showContactDetailModal(item.client_user_id)" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import InviteMemberItem from "../Item/InviteMemberItem";
export default {
  components: {
    "invite-member-item": InviteMemberItem,
  },
  data() {
    return {
      projectId: 0,
      memberList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  methods: {
    ...mapActions({
      projectGetJoinedMemberListAction: "projectGetJoinedMemberList",
    }),
    tabChange(index) {
      this.currentTab = index;
    },
    showContactDetailModal(id) {
      this.$emit("show-contact-detail-modal", id);
    },
    refresh() {
      this.projectGetJoinedMemberListAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          this.memberList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.invite-member-container {
  display: flex;
  flex-direction: column;
}
.invite-member-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invite-title {
  font-size: 23px;
  font-weight: bold;
  margin-right: 30px;
}
.invite-member-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
</style>